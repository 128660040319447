<template>
    <div class="loader-wrapper">
        <div class="loader"></div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'LoaderAnimationItem',
  setup() {

  },
});
</script>

<style lang="scss" scoped>
    .loader-wrapper {
        position: relative;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
    }
    .loader {
        position: relative;
        display: inline-block;
        width: 80px;
        height: 80px;
        border: 3px solid #3485FF;
        border-radius: 50%;
        -webkit-animation: rotation 0.75s infinite linear;
        animation: spin 0.75s infinite linear;
        border-top-width: 0;

        &::before,
        &::after {
            left: -2px;
            top: -2px;
            display: none;
            position: absolute;
            content: '';
            width: inherit;
            height: inherit;
            border: inherit;
            border-radius: inherit;
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        from {
        -webkit-transform: rotate(0deg);
        }
        to {
        -webkit-transform: rotate(360deg);
        }
    }
</style>
