var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings"},[_c('header',{staticClass:"modal-header"},[_c('h1',{staticClass:"modal-header__title"},[_vm._v("Settings")]),_c('button',{staticClass:"modal-header__btn",on:{"click":function($event){$event.preventDefault();return _vm.closeModal()}}},[_c('CrossIcon',{staticClass:"modal-header__btn-icon"})],1)]),_c('CustomScroll',[_c('div',{staticClass:"settings__content"},[_c('div',{staticClass:"settings__content-section"},[_c('div',{staticClass:"settings__content-section-overhead"},[_c('span',{staticClass:"settings__content-section-overhead-label"},[_vm._v(" Global ")])]),_c('div',{staticClass:"settings__content-section-inner"},[_c('h1',{staticClass:"settings__content-section-title"},[_vm._v(" Default Transaction Speed (GWEI) ")]),_c('div',{staticClass:"settings__content-section-item"},_vm._l((_vm.speedBtns),function(btn){return _c('button',{key:btn.id,class:{
                'settings__content-section-item-radio-btn': true,
                selected: btn.selected,
              },on:{"click":function($event){$event.preventDefault();return _vm.selectBtn(btn, _vm.speedBtns)}}},[_vm._v(" "+_vm._s(btn.label)+" ("+_vm._s(btn.amount)+") ")])}),0)])]),_c('div',{staticClass:"settings__content-section"},[_c('div',{staticClass:"settings__content-section-overhead"},[_c('span',{staticClass:"settings__content-section-overhead-label"},[_vm._v(" Swaps and Liquidity ")])]),_c('div',{staticClass:"settings__content-section-inner"},[_c('h1',{staticClass:"settings__content-section-title"},[_vm._v(" Slippage Tolerance ")]),_c('div',{staticClass:"settings__content-section-item"},[_vm._l((_vm.toleranceBtns),function(btn){return _c('button',{key:btn.id,class:{
                'settings__content-section-item-radio-btn': true,
                selected: btn.selected,
              },on:{"click":function($event){$event.preventDefault();return _vm.selectBtn(btn, _vm.toleranceBtns)}}},[_vm._v(" "+_vm._s(btn.amount)+"% ")])}),_c('input',{staticClass:"settings__content-section-item-input",attrs:{"type":"text","placeholder":"0.50","pattern":"^[0-9]*[.,]?[0-9]*$"}}),_c('span',{staticClass:"settings__content-section-item-percent-sign"},[_vm._v(" % ")])],2),_c('div',{staticClass:"settings__content-section-item space-between"},[_c('h3',{staticClass:"settings__content-section-item-name"},[_vm._v(" TX deadline (mins) ")]),_c('input',{staticClass:"settings__content-section-item-input",attrs:{"type":"text","placeholder":"20"}})]),_vm._l((_vm.settingsWithSwitch),function(setting){return _c('div',{key:setting.id,staticClass:"settings__content-section-item space-between"},[_c('h3',{staticClass:"settings__content-section-item-name"},[_vm._v(" "+_vm._s(setting.name)+" ")]),_c('button',{class:{
                'settings__content-section-item-switch-btn': true,
                selected: setting.selected,
              },on:{"click":function($event){$event.preventDefault();return _vm.switchSetting(setting)}}},[_c('span',{staticClass:"settings__content-section-item-switch-btn-slider"})])])})],2)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }